<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field
          class="mr-5"
          label="Data"
          solo
          flat
          background-color="grey lighten-4"
          @change="select()"
          v-model="params.date_start"
          type="date"
        />
        <app-text-field
          class="ml-5"
          label="Data"
          solo
          flat
          background-color="grey lighten-4"
          @change="select()"
          v-model="params.date_end"
          type="date"
        />
      </v-col>
    </v-row>

    <v-divider class="my-4" />

    <h4>{{ getAccountPlanGroup("1.1").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('1.1')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td width="10%" class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td width="10%" class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("1.1", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>


    <h4 class="mt-4">{{ getAccountPlanGroup("2.1").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.1')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.1", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>


    <h4 class="mt-4">{{ getAccountPlanGroup("2.2").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.2')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.2", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("2.3").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.3')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.3", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("2.4").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.4')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.4", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("1.2").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('1.2')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("1.2", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("2.5").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.5')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.5", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("1.3").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('1.3')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("1.3", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="mt-4">{{ getAccountPlanGroup("2.6").name }}</h4>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Conta
            </th>
            <template v-for="(month, index) of months">
              <th width="10%" class="text-right" :key="index">
                {{ month }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(account_plan, index) of getAccountPlanGroup('2.6')
              .account_plans"
          >
            <tr :key="index">
              <td>
                {{ account_plan.name }}
              </td>
              <template v-for="(month, index) of months">
                <td class="text-right" :key="index">
                  {{ $format.decimal(getAccountPlanValue(account_plan.id, month)) }}
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <template v-for="(month, index) of months">
              <td class="text-right" :key="index">
                <b>{{ $format.decimal(getAccountPlanGroupSum("2.6", month)) }}</b>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- <template v-for="(account_plan, index) of getAccountPlanGroup('1.1').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("1.1")) }}</b>
      </v-col>
    </v-row>
   

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.1").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.1').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.1")) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.2").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.2').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.2")) }}</b>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"><b>Total de despesas variaveis</b></v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getVariableCostsSum()) }}</b>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"><b>Lucro bruto</b></v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getGrossProfit()) }}</b>
        <br>
        <small>{{ $format.decimal(getGrossProfitPercent()) }} %</small>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.3").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.3').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.3")) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.4").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.4').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.4")) }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"><b>Lucro Operacional</b></v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getOperationalProfit()) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("1.2").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('1.2').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("1.2")) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.5").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.5').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.5")) }}</b>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"><b>Lucro Líquido</b></v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getNetProfit()) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("1.3").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('1.3').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("1.3")) }}</b>
      </v-col>
    </v-row>

    <h4 class="mt-10 mb-4">{{ getAccountPlanGroup("2.6").name }}</h4>
    <v-divider />
    <template v-for="(account_plan, index) of getAccountPlanGroup('2.6').account_plans">
      <v-row :key="index">
        <v-col cols="4">
          {{ account_plan.name }}
          <small class="text--secondary">({{ account_plan.code }}) </small>
        </v-col>
        <v-col class="text-right" cols="2">
          getAccountPlanValue(account_plan.id)) }}
        </v-col>
      </v-row>
      <v-divider :key="index +'divider'"/>
    </template>
    <v-row>
      <v-col cols="4"> Total </v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getAccountPlanGroupSum("2.6")) }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"><b>Resultado do fluxo de caixa</b></v-col>
      <v-col class="text-right" cols="2">
        <b>{{ $format.decimal(getResult()) }}</b>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import {
  format,
  startOfMonth,
  lastDayOfMonth,
  parseISO,
  eachMonthOfInterval,
  subMonths,
} from "date-fns";
import AppDatePicker from "@/components/app/ui/AppDatePicker";
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";
import AccountTransactionDialog from "@/components/account/sections/AccountTransactionDialog";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import paymentMethods from "@/enums/paymentMethods";

export default {
  components: {
    AppDatePicker,
    AccountTransactionLabel,
    AccountTransactionDialog,
    AccountSelect,
  },

  data() {
    return {
      accountPlanGroups: [],
      accountTransactions: [],
      dates: [],
      data: [],
      months: [],
      date: format(new Date(), "yyyy-MM"),
      params: {
        account_plan_id_diferent: null,
        date_start: null,
        date_end: null,
        status: ["paid"],
      },
    };
  },

  async mounted() {
    // console.log(format(new Date(), 'yyyy-MM'))
    // this.setDateParams(this.date)
    await this.setDateParams();
    this.setMonths();
    await this.selectAccountPlans();
    await this.select();

    // this.setDates();
    // this.setData();
  },

  methods: {
    setDateParams() {
      // get first day of last six months 
      this.params.date_start = format(startOfMonth(subMonths(new Date(), 6)), 'yyyy-MM-dd')
      // get last day of this month
      this.params.date_end = format(lastDayOfMonth(new Date()), 'yyyy-MM-dd')
    },

    setMonths() {
      let months = eachMonthOfInterval({
        start: parseISO(this.params.date_start),
        end: parseISO(this.params.date_end),
      });

      this.months = months.map((month) => format(month, "yyyy-MM"));
    },

    async selectAccountPlans() {
      await this.$http.index("account/account-plan-group").then((response) => {
        this.accountPlanGroups = response.accountPlanGroups;
      });
    },

    async select() {
      this.setMonths();
      await this.$http
        .index("bi/account-transaction", this.params)
        .then((response) => {
          this.accountTransactions = response.accountTransactions;
        });
    },

    // ********* old

    getAccountPlanGroup(groupCode) {
      let accountPlanGroup = this.accountPlanGroups.filter(
        (value) => value.code == groupCode
      );
      if (accountPlanGroup[0]) {
        return accountPlanGroup[0];
      }

      return {};
    },

    getAccountPlanValue(accountPlanId, month) {
      let row = this.accountTransactions.filter(
        (value) => value.account_plan_id == accountPlanId && value.date_month == month
      );

      if (row.length > 0) {
        return row[0].value_sum;
      }

      return 0;
    },

    getAccountPlanGroupSum(code, month) {
      let accountPlans = this.getAccountPlanGroup(code).account_plans;

      if (typeof accountPlans == "undefined") {
        return 0;
      }

      var sum = 0
      
      for (let accountPlan of accountPlans) {
        if (accountPlan) {
          sum += Number(this.getAccountPlanValue(accountPlan.id, month))
        }
      }

      return sum
    },

    getVariableCostsSum() {
      return (
        this.getAccountPlanGroupSum("2.1") + this.getAccountPlanGroupSum("2.2")
      );
    },

    getGrossProfit() {
      return this.getAccountPlanGroupSum("1.1") + this.getVariableCostsSum();
    },

    getGrossProfitPercent() {
      return (this.getGrossProfit() * 100) / this.getAccountPlanGroupSum("1.1");
    },

    getOperationalProfit() {
      return (
        this.getGrossProfit() +
        this.getAccountPlanGroupSum("2.3") +
        this.getAccountPlanGroupSum("2.4")
      );
    },

    getNetProfit() {
      return (
        this.getOperationalProfit() +
        this.getAccountPlanGroupSum("1.2") +
        this.getAccountPlanGroupSum("2.5")
      );
    },

    getResult() {
      return this.$calc.sum(this.accountTransactions, "value_sum");
    },

    
  },
};
</script>

<style></style>
